<section class="page-content fadeIn">
  <h1><span>Treatment of Periodontal Disease</span></h1>
  <p>
    Periodontal Disease (Periodontitis) is a bacterial infection that affects the supporting structures of teeth. In most cases Periodontitis does not cause any symptoms until it reaches advanced stages of progression. The bacterial infection destroys bone around teeth and causes the gums to separate from the tooth surface. As a result, “pockets” form around the affected teeth which harbor more bacteria leading to a progressively severe infection (Figure 1). For more information on causes and effects of periodontal disease click here or visit our “Patient Information” section.
  </p>
  <div class="figureGallery lightGalleryTheme">
    <div class="figurelist primary-figure-list">
      <figure>
        <img src="../../assets/imgs/treatment-of-periodontal-disease/atraumatic_clip_image001.jpg"
        alt="Figure 1: Effects of Periodontal Disease"/>
        <figcaption>Figure 1: Effects of Periodontal Disease</figcaption>
      </figure>
    </div>
  </div>
  <p>
    The goal of periodontal therapy is to decrease the presence of bacteria around teeth. This is accomplished through non surgical and in some cases surgical procedures.
  </p>
  <h2>Scaling and Root Planing</h2>
  <p>
    Typical periodontal treatment usually is initiated by thorough debridement called Scaling and Root Planing (SRP). Scaling and Root Planing is intended to remove plaque and tartar deposits under the gums. As a result, the tissues around teeth become healthier and less inflamed due to decreased amounts of bacteria under the gums (Figure 2a-d). Most patients experience less bleeding from the gums after completion of SRP. Scaling and Root Planing is usually completed in two to three appointments. For patient’s comfort, the gums are anesthetized with local anesthetic or topical numbing gels during the appointment. The condition of the gums is re-evaluated about a month after completion of SRP.
  </p>
  <div class="figureGallery darkGalleryTheme">
    <div class="figurelist">
      <figure>
        <img src="../../assets/imgs/scaling-and-root-planing/atraumatic_clip_image003.jpg"
        alt="Figure 2a: Inflamed and Swollen Gums">
        <figcaption>Figure 2a: Inflamed and Swollen Gums</figcaption>
      </figure>
      <figure>
        <img src="../../assets/imgs/scaling-and-root-planing/atraumatic_clip_image005.jpg"
        alt="Figure 2b: Inflamed and Swollen Gums"/>
        <figcaption>Figure 2b: Inflamed and Swollen Gums</figcaption>
      </figure>
      <figure>
        <img src="../../assets/imgs/scaling-and-root-planing/atraumatic_clip_image007.jpg"
        alt="Figure 2c: Gums Before SRP"/>
        <figcaption>Figure 2c: Gums Before SRP</figcaption>
      </figure>
      <figure>
        <img src="../../assets/imgs/scaling-and-root-planing/atraumatic_clip_image009.jpg"
        alt="Figure 2d: Gums After SRP"/>
        <figcaption>Figure 2d: Gums After SRP</figcaption>
      </figure>
    </div>
  </div>
  <h2>Periodontal Surgery</h2>
  <p>
    In moderate and advanced cases of periodontal disease, Scaling and Root Planing alone may not provide enough benefit. If after SRP, the condition of the gums has not sufficiently improved and periodontal pockets around teeth are still remaining, minor surgical treatment may be indicated. Periodontal surgery is intended to thoroughly debride areas that were not reachable with SRP and further reduce the pockets around teeth. This procedure is done with local anesthetic, however patients may choose to be sedated to relieve any anxiety. During the procedure the gums are carefully trimmed to eliminate infected and unhealthy tissue, and roots of the teeth are cleaned. Gums are then repositioned using sutures (Figures 1a-c).
  </p>
  <div class="figureGallery darkGalleryTheme">
    <div class="figurelist">
      <figure>
        <img src="../../assets/imgs/periodontal-surgery/atraumatic_clip_image011.jpg"
        alt="Figure 3a: Periodontal pockets remaining after SRP">
        <figcaption>Figure 3a: Periodontal pockets remaining after SRP</figcaption>
      </figure>
      <figure>
        <img src="../../assets/imgs/periodontal-surgery/atraumatic_clip_image013.jpg"
        alt="Figure 3b: Pockets treated with periodontal surgery"/>
        <figcaption>Figure 3b: Pockets treated with periodontal surgery</figcaption>
      </figure>
      <figure>
        <img src="../../assets/imgs/periodontal-surgery/atraumatic_clip_image015.jpg"
        alt="Figure 3c: Gums repositioned with sutures at the completion of surgery"/>
        <figcaption>Figure 3c: Gums repositioned with sutures at the completion of surgery</figcaption>
      </figure>
    </div>
  </div>
  <h2>Bone Grafting and Regeneration</h2>
  <p>
    During periodontal surgery it may be possible to regenerate the bone around teeth which was lost due to periodontal disease. This is accomplished by placing a bone graft in the areas around teeth (Figures 1a-d). Recent developments in techniques and materials have greatly expanded treatment options with growth factors and biologic modifiers. These materials induce the bone to grow at a faster rate and provide more predictable results.
  </p>
  <div class="figureGallery darkGalleryTheme">
    <div class="figurelist">
      <figure>
        <img src="../../assets/imgs/bone-grafting-and-regeneration/atraumatic_clip_image017.gif"
        alt="Figure 4a: Bone loss around a tooth as a result of Periodontal Disease">
        <figcaption>Figure 4a: Bone loss around a tooth as a result of Periodontal Disease</figcaption>
      </figure>
      <figure>
        <img src="../../assets/imgs/bone-grafting-and-regeneration/atraumatic_clip_image019.gif"
        alt="Figure 4b: Defect around the tooth is cleaned during a surgical procedure"/>
        <figcaption>Figure 2b: Defect around the tooth is cleaned during a surgical procedure</figcaption>
      </figure>
      <figure>
        <img src="../../assets/imgs/bone-grafting-and-regeneration/atraumatic_clip_image021.gif"
        alt="Figure 4c: Defect in the bone is filled with a bone graft material and growth factors"/>
        <figcaption>Figure 4c: Defect in the bone is filled with a bone graft material and growth factors</figcaption>
      </figure>
      <figure>
        <img src="../../assets/imgs/bone-grafting-and-regeneration/atraumatic_clip_image023.gif"
        alt="Figure 4d: Newly regenerated bone is formed around the affected tooth"/>
        <figcaption>Figure 4d: Newly regenerated bone is formed around the affected tooth</figcaption>
      </figure>
    </div>
  </div>
  <p>
    Typically about six months is needed for the new bone to mature after a regeneration procedure. As a result, increased support around the tooth is formed. Regenerated bone can be observed on an X-Ray at the end of the healing period (Figures 2a-b).
  </p>
  <div class="figureGallery darkGalleryTheme">
    <div class="figurelist">
      <figure>
        <img src="../../assets/imgs/bone-grafting-and-regeneration/atraumatic_clip_image025.jpg"
        alt="Figure 5a: Bone loss as a result of periodontal disease">
        <figcaption>Figure 5a: Bone loss as a result of periodontal disease</figcaption>
      </figure>
      <figure>
        <img src="../../assets/imgs/bone-grafting-and-regeneration/atraumatic_clip_image027.jpg"
        alt="Figure 5b: Newly formed bone after a regeneration procedure"/>
        <figcaption>Figure 5b: Newly formed bone after a regeneration procedure</figcaption>
      </figure>
    </div>
  </div>
  <h2>Periodontal Maintenance</h2>
  <p>
    Following the completion of periodontal therapy, a periodontal maintenance program is recommended to prevent recurrence of the disease. Much like blood pressure problems, or diabetes, control of periodontal disease requires commitment on the part of the patient and treating doctors. In addition to proper oral hygiene, dental cleanings four times per year have been shown to significantly reduce recurrence of periodontal disease and tooth loss. By having your teeth and gums thoroughly cleaned every three months, bacteria that can cause periodontal disease is drastically reduced. Periodontal maintenance is typically arranged so patients alternate their visits every three months between the periodontist and their general dentist.
  </p>
</section>
