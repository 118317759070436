<section class="page-content">
  <div class="row mobile-banner align-center flex-end">
  </div>
  <div class="row home-banner" *ngIf="!mobileQuery720.matches">
    <div class="banner-left"></div>
    <div class="banner-right">
      <div>
        <p>
          8350 East Kemper Rd Unit C<br>
          Cincinnati, OH 45249<br>
          <a href="tel:+5139844867">513.984.4867</a>
          <br>
          <br>
          <a class="directions-link" (click)="goTo('contact')">Get Directions...</a>
        </p>
      </div>
      <div>
        <p>
          7665 Monarch Ct<br>
          West Chester Township, OH 45069<br>
          <a href="tel:+5135636611">513.563.6611</a>
          <br>
          <br>
          <a class="directions-link" (click)="goTo('contact')">Get Directions...</a>
        </p>
      </div>
    </div>
  </div>
  <div class="locations-mobile" *ngIf="mobileQuery720.matches">
    <div>
      <p>
        8350 East Kemper Rd Unit C<br>
        Cincinnati, OH 45249<br>
        <a href="tel:+5139844867">513.984.4867</a>
        <br>
        <br>
        <a class="directions-link" (click)="goTo('contact')">Get Directions...</a>
      </p>
    </div>
    <div>
      <p>
        7665 Monarch Ct<br>
        West Chester Township, OH 45069<br>
        <a href="tel:+5135636611">513.563.6611</a>
        <br>
        <br>
        <a class="directions-link" (click)="goTo('contact')">Get Directions...</a>
      </p>
    </div>
  </div>
  <div class="hour-banner align-center flex-gap-1em">
    <img src="../../assets/imgs/home/aap_logo_inverse.png" width="124" height="124" alt="aap logo"/>
    <table>
      <tr>
        <td>{{mobileQuery480.matches ? 'MON' :'Monday'}}</td>
        <td>8:00 - 4:30</td>
        <td>(Lunch 12:00 - 1:30)</td>
      </tr>
      <tr>
        <td>{{mobileQuery480.matches ? 'TUE' :'Tuesday'}}</td>
        <td>8:30 - 5:00</td>
        <td>(Lunch 12:30 - 2:00)</td>
      </tr>
      <tr>
        <td>{{mobileQuery480.matches ? 'WED' :'Wednesday'}}</td>
        <td>8:30 - 12:00</td>
        <td>(No doctors, staff only)</td>
      </tr>
      <tr>
        <td>{{mobileQuery480.matches ? 'THU' :'Thursday'}}</td>
        <td>8:00 - 4:30</td>
        <td>(Lunch 12:00 - 1:30)</td>
      </tr>
      <tr>
        <td>{{mobileQuery480.matches ? 'FRI' :'Friday'}}</td>
        <td>8:00 - 3:00</td>
        <td>(Lunch 12:00 - 1:30)</td>
      </tr>
    </table>
  </div>
  <h1><span>Welcome</span></h1>
  <p>
    Thank you for visiting the website of Drs. Shapiro, Baker and Weber. Our practice specializes in comprehensive periodontal therapy, gingival grafting, oral plastic surgery, and dental implants. Our mission is to provide you with the most current treatment available and ensure that your experience in our practice is pleasant and productive. We work closely with your general dentist and we individualize your treatment needs in order to achieve and maintain your oral health.
  </p>
  <p>
    Our website is designed to provide information about our practice and the periodontal specialty. We encourage our current and future patients to take time and review the information. We strive to achieve and maintain our commitment, approachability, and communication with our patients. Our dedicated doctors and staff make every effort to keep you well informed and educated during the treatment process.
  </p>
</section>
