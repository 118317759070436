<section class="page-content fadeIn">
  <h1><span>Referrals</span></h1>
  <p><a href="http://healmygums.com/assets/pdfs/referral/Referral-Card-for-Website.pdf" target="_blank" alt="Downlaod the referral form">Download the referral form</a></p>
  <h2>Continuing Education Courses</h2>
  <p>
    We feel that it is our responsibility as well as a privilege to actively provide continuing education to our referring colleagues. Being certified CE providers allows us to structure and to teach programs on a wide variety of topics in our specialty. We find this aspect of our work especially enjoyable and rewarding as it provides us with an opportunity to personally interact with our colleagues and to receive their feedback on the current trends in our profession. Below is the list of programs and courses that we currently offer. Continuing Education credits approved by the Ohio Dental Board are issued for each course attended. New programs are always being developed, so be sure to check with us periodically.
  </p>
  <h2>Annual Dental Hygiene Symposium (2.5 CE Credits)</h2>
  <p>
    Dental Hygiene staff is vital in providing comprehensive periodontal treatment to your patients. This two to three hour program is specifically designed to provide the dental hygienist with essential knowledge which would assist the restorative dentist in diagnosis, treatment planning, and communication with your patients. The topics covered in this program include etiology of periodontal disease, surgical and non-surgical treatment, laser therapy, soft tissue grafting, and dental implants. The course is offered annually on two separate dates in an effort to accommodate everyone. Invitations are usually sent out six to eight weeks prior to the course date. Feel free to contact our office for more information on the upcoming course.
  </p>
  <h2>Lunch and Learn Programs for Dentists, Hygienists, and Office Staff (1 to 3 CE Credits)</h2>
  <p>
    These informal programs are conducted in your office during the lunch hour or after regularly scheduled patient hours. The lectures are intended to provide an informal setting where the attendees can freely ask questions and discuss the lecture topic. Lunch is provided by Drs. Shapiro and Baker along with CE credits for the doctors and the office staff. Below is the most current list of available course topics.
  </p>
  <h2>Topics</h2>
  <ul>
    <li>Periodontal Diagnosis and Treatment</li>
    <li>Periodontal and Implant Maintenance</li>
    <li>Myths and Realities of Laser Periodontal Therapy</li>
    <li>Current Concepts of Gingival Grafting</li>
    <li>Periodontal Considerations in Pediatric and Orthodontic Patients</li>
    <li>Esthetic Considerations in Restorative and Implant Dentistry</li>
    <li>Preventing Implant Complications</li>
    <li>Treatment Options for Peri-Implantitis</li>
    <li>Soft Tissue Management Around Dental Implants</li>
    <li>Benefits of Atraumatic Extraction and Socket Preservation</li>
    <li>Bone Grafting for Implant Site Development</li>
    <li>Surgical and Restorative Considerations for Implant Hybrid Prosthesis</li>
    <li>Partial and Complete Overdentures</li>
    <li>Immediate Load of Implants in the Esthetic Zone</li>
  </ul>
</section>
